import React from 'react'
import { Typography, Box, Avatar } from '@material-ui/core'
import { CustomButton } from './style'
import { dateNow } from '../../utils/dateHandler'
import { POP_TYPES, validateAudio, validateImage, validateVideo } from './utils'
import './styles.css'
import ThreeSixtyOutlinedIcon from '@material-ui/icons/ThreeSixtyOutlined'
import ReplyAllIcon from '@material-ui/icons/ReplyAll'
import AvatarPlaceholder from '../../assets/svg-icons/avatar-placeholder.svg'
import { PostMediaGrid } from '../../components/VideoPlayer/index'
import { FbGridVideoPreview } from '../../components/VideoPlayer/FbGridVideoPreview'
import AudioPlayer from '../../components/AudioPlayer'

export const InfoPopBody = ({
  popType,
  isRepopView,
  setIsRepopView,
  profileImageUrl,
  setProfileImageError,
  popTitle,
  popBody,
  pop_image_url,
  pop_video_url,
  pop_audio_url,
  ackBtn,
  repop_main_btn,
  repop_sec_btn,
  setPopImageError,
  setVideoLinkError,
  setAudioLinkError,
}) => {
  return (
    <>
      {popType === POP_TYPES.RE_POP && (
        <CustomButton
          type="button"
          width="max-content"
          onClick={() => setIsRepopView(prev => !prev)}
          endIcon={<ThreeSixtyOutlinedIcon />}
          style={{
            position: 'absolute',
            left: '40px',
            top: '20px',
            background: 'white',
            color: 'black',
          }}
        >
          {!isRepopView ? 'Default POP' : 'Re-POP'}
        </CustomButton>
      )}

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '53px',
          width: '415px',
          background: 'white',
          margin: '100px auto 20px auto',
          textAlign: 'center',
          padding: '0 10% 0 10%',
        }}
      >
        {profileImageUrl ? (
          <>
            {validateImage(profileImageUrl, setProfileImageError) && (
              <Avatar
                src={profileImageUrl}
                style={{
                  background: 'white',
                  width: '110pt',
                  height: '110pt',
                  margin: '-25% auto 2%',
                }}
              />
            )}
          </>
        ) : (
          <Avatar
            src={AvatarPlaceholder}
            style={{
              background: 'white',
              width: '110pt',
              height: '110pt',
              margin: '-25% auto 2%',
            }}
          />
        )}

        <div style={{ fontSize: '11pt' }}>{dateNow()}</div>
        <Box
          style={{
            marginTop: '15pt',
            wordWrap: 'break-word',
            width: '100%',
            maxWidth: '236pt',
          }}
        >
          <Typography style={{ fontSize: '18pt', fontFamily: 'Arial-BoldMT' }}>
            {popTitle}
          </Typography>
        </Box>
        {popType !== POP_TYPES.AI_POP && popType !== POP_TYPES.AI_REPOP && (
          <Box
            style={{
              marginTop: '10pt',
              width: '100%',
              maxWidth: '220pt',
            }}
          >
            <div
              id="wrapper_html_text"
              style={{
                overflow: 'hidden',
                maxHeight: '118pt',
              }}
            >
              <div
                id="html_text"
                className="pop_text"
                dir="auto"
                style={{
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'ArialMT',
                  lineHeight: '20px',
                  fontSize: '17px',
                  '-webkit-line-clamp': 7,
                  lineClamp: 7,
                  // ' -webkit-box-orient': 'vertical',
                }}
              >
                {popBody}
              </div>
            </div>
          </Box>
        )}
        {validateImage(pop_image_url, setPopImageError) && (
          <div>
            <img
              style={{
                width: '90pt',
                height: '90pt',
                marginTop: '10pt',
              }}
              src={pop_image_url}
              alt="image pop"
            />
          </div>
        )}
        {pop_video_url && validateVideo(pop_video_url, setVideoLinkError) && (
          <PostMediaGrid media={[pop_video_url]}>
            {[pop_video_url].map(videoSrc => (
              <FbGridVideoPreview key={videoSrc} src={videoSrc} />
            ))}
          </PostMediaGrid>
        )}
        {pop_audio_url && validateAudio(pop_audio_url, setAudioLinkError) && (
          <AudioPlayer src={pop_audio_url} />
        )}
        {popType !== POP_TYPES.FAKE_REPOP &&
        (!isRepopView || popType === POP_TYPES.DEFAULT_POP) ? (
          <>
            {ackBtn && (
              <CustomButton
                style={{
                  fontSize: '14pt',
                  fontFamily: 'ArialMT',
                  marginBottom: '30pt',
                  marginTop: '15pt',
                  borderRadius: '20.5pt',
                  height: '32pt',
                  width: '179pt',
                  color: 'white',
                  paddingTop: '13pt',
                  paddingBottom: '13pt',
                  background: '#879AF2',
                  textTransform: 'none',
                }}
              >
                {ackBtn}
              </CustomButton>
            )}
          </>
        ) : (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {repop_main_btn && (
              <CustomButton
                endIcon={<ReplyAllIcon style={{ transform: 'scaleX(-1)' }} />}
                style={{
                  fontSize: '14pt',
                  fontFamily: 'ArialMT',
                  marginBottom: '15pt',
                  marginTop: '15pt',
                  borderRadius: '20.5pt',
                  height: '32pt',
                  width: '179pt',
                  color: 'white',
                  paddingTop: '13pt',
                  paddingBottom: '13pt',
                  background: '#879AF2',
                  textTransform: 'none',
                }}
              >
                {repop_main_btn}
              </CustomButton>
            )}
            {repop_sec_btn && (
              <Typography
                style={{
                  cursor: 'pointer',
                  color: '#879AF2',
                  marginBottom: '5pt',
                }}
              >
                {repop_sec_btn}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}
