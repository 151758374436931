import { Menu, MenuItem, Button, Box, Typography } from '@material-ui/core'
import React from 'react'
import { useStyles } from './style'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

export const POP_TYPES = [
  { type: 1, title: 'Default POP' },
  { type: 2, title: 'Re POP' },
  { type: 3, title: 'ChatGPT POP' },
  { type: 4, title: 'Fake Re POP' },
  { type: 5, title: 'AI POP' },
  { type: 6, title: 'AI Re POP' },
]
export const PopTypeDropdown = ({ watch, setValue }) => {
  const popType = watch('pop_type')
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelect = type => {
    setAnchorEl(null)
    setValue('pop_type', type)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}>
        <Typography>POP Type</Typography>
        <Button
          className={classes.menuBtn}
          aria-controls="simple-menu"
          aria-haspopup="true"
          endIcon={<ArrowDropDownIcon />}
          onClick={handleClick}
        >
          {POP_TYPES.find(type => type.type === popType).title}
        </Button>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ top: '50px' }}
      >
        {POP_TYPES.map(({ type, title }) => (
          <MenuItem
            key={type}
            onClick={() => handleSelect(type)}
            style={{
              backgroundColor: popType === type ? '#6b7de2' : 'white',
              color: popType === type ? 'white' : 'black',
            }}
          >
            {title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
