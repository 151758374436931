import React, { useEffect, useState } from 'react'
import { connect } from 'redux-zero/react'
import { useForm } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { CreatePopWrapper } from './style'
import { Divider } from '@material-ui/core'
import { createPop, createPromt } from '../../services/api'
import PopView from './PopView'
import Keys from '../../utils/Keys'
import actions from '../../store/actions'
import { CreatePop } from './CreatePop'
import { DEFAULT_FORM_VALUES, POP_TYPES } from './utils'

const PopCreation = ({
  users,
  selected_rid,
  logout,
  handleAlert,
  retailTags,
  groups,
}) => {
  const [isTimeLess, setIsTimeLess] = useState(false)
  const [checkedIds, setCheckedIds] = useState([])
  const [checkedRepopIds, setCheckedRepopIds] = useState([])
  const [checkedTags, setCheckedTags] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isPopBodyHasMaxHeight, setPopBodyHasMaxHeight] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const [isPopImageError, setPopImageError] = useState(false)
  const [isProfileImageError, setProfileImageError] = useState(false)
  const [isVideoLinkError, setVideoLinkError] = useState(false)
  const [isAudioLinkError, setAudioLinkError] = useState(false)
  const [isRepopView, setIsRepopView] = useState(false)
  const [isPopPost, setIsPopPost] = useState(false)
  const [isCustomAvatar, setIsCustomAvatar] = useState(false)
  const [checkedDefaultPop, setCheckedDefaultPop] = useState(false)
  const [checkedRepop, setCheckedRepop] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
    resetField,
    setValue,
  } = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
  })

  const popType = watch('pop_type')
  const repopContextsIds = watch('repop_contexts_ids')
  const { field } = useController({
    control,
    name: 'contexts_ids',
    rules: {
      required:
        popType === POP_TYPES.FAKE_REPOP || checkedTags.length > 0
          ? false
          : 'Please select users',
      minLenght: 1,
    },
  })
  const { field: tags_context } = useController({
    control,
    name: 'tags_context',
    rules: {
      required:
        popType === POP_TYPES.FAKE_REPOP || watch('contexts_ids')?.length > 0
          ? false
          : 'Please select tags',
      minLenght: 1,
    },
  })

  const { field: repop_contexts_ids } = useController({
    control,
    name: 'repop_contexts_ids',
    rules: {
      required:
        popType === POP_TYPES.RE_POP || popType === POP_TYPES.AI_REPOP
          ? 'Please select user'
          : false,
      minLenght: 1,
    },
  })
  const handleResponseError = status => {
    if (status === '401') {
      logout()
    }
  }
  const handleSubmissionResponse = response => {
    let msg = 'ERROR: changes were not saved, something is wrong with the data'
    let alertType = Keys.ALERT_ERROR

    if (response !== undefined && response.status === 200) {
      msg = 'Changes Were Saved :) !'
      alertType = Keys.ALERT_SUCCESS
      reset()
      setCheckedIds([])
      setCheckedRepopIds([])
      setCheckedTags([])
      setIsSubmitted(true)
      setVideoLinkError(false)
      setAudioLinkError(false)
      setPopImageError(false)
    }

    handleAlert({
      text: msg,
      type: alertType,
    })
    handleResponseError(response.status.toString())
  }

  const calculateAudience = (data, isRepop = false) => {
    const { contexts_ids = [], repop_contexts_ids = [] } = data

    const users = isRepop
      ? Array.from(new Set([...contexts_ids, ...repop_contexts_ids]))
      : contexts_ids
    const groups = []
    const tags = checkedTags

    return { users, groups, tags }
  }
  const profileImageUrl = (pop_type, profile_image_url) => {
    if (pop_type === POP_TYPES.FAKE_REPOP) {
      return null
    }
    if (
      pop_type === POP_TYPES.DEFAULT_POP ||
      isCustomAvatar ||
      pop_type === POP_TYPES.AI_POP
    ) {
      return profile_image_url || null
    } else {
      const selectedRepopUser = users.find(
        user => user.uid === repopContextsIds?.[0]
      )

      return selectedRepopUser?.avatar_profile_img_url
        ? selectedRepopUser?.avatar_profile_img_url
        : selectedRepopUser?.profile_img_url || null
    }
  }

  const onSubmit = async (data, e) => {
    setIsLoading(true)
    e.preventDefault()

    if (isPopBodyHasMaxHeight || isPopImageError || isProfileImageError) {
      return
    }

    try {
      const commonProps = {
        rid: selected_rid,
        is_long_range_pop: isTimeLess,
        pop_type: data.pop_type,
      }
      const computedProfileImageUrl = profileImageUrl(
        data.pop_type,
        data.profile_image_url
      )

      let body

      switch (data.pop_type) {
        case POP_TYPES.CHAT_GPT:
          body = {
            ...commonProps,
            data: {
              push_title: data.push_title,
              push_body: data.push_body,
              pop_body: data.pop_body,
              ack_btn_text: 'some text',
            },
          }
          break

        case POP_TYPES.AI_POP: {
          const promtBody = {
            prompt: data.pop_body,
            ...calculateAudience(data),
          }
          const response = await createPromt(promtBody)
          if (!response || !response?.status === 200 || !response?.prompt_id) {
            return
          }
          const pop_data_id = response?.prompt_id

          body = {
            ...commonProps,
            is_send_push_notification: checkedDefaultPop,
            data: {
              prompt_id: pop_data_id,
              pop_title: data.pop_title,
              pop_body: '',
              ack_btn_text: data.ack_btn_text,
              push_title: data.push_title,
              push_body: data.push_body,
              repop_push_title: '',
              repop_push_body: '',
              profile_image_url: computedProfileImageUrl,
              pop_image_url: null,
            },
            ...calculateAudience(data),
          }
          break
        }
        case POP_TYPES.AI_REPOP: {
          const promtBody = {
            prompt: data.pop_body,
            ...calculateAudience(data, true),
          }
          const response = await createPromt(promtBody)
          if (!response || !response?.status === 200 || !response?.prompt_id) {
            return
          }
          const pop_data_id = response?.prompt_id
          body = {
            ...commonProps,
            is_send_push_notification: checkedDefaultPop,
            data: {
              prompt_id: pop_data_id,
              pop_title: data.pop_title,
              pop_body: '',
              ack_btn_text: data.ack_btn_text,
              push_title: data.push_title,
              push_body: data.push_body,
              repop_push_title: '',
              repop_push_body: '',
              profile_image_url: computedProfileImageUrl,
              pop_image_url: null,
              is_repop_send_push_notification: checkedRepop,
              repop_main_btn: data.repop_main_btn,
              repop_sec_btn: data.repop_sec_btn,
              repop_users: data.contexts_ids,
              repop_tags: data.tags_context,
              repop_groups: [],
            },
            users: data.repop_contexts_ids,
          }
          break
        }

        default:
          // DEFAULT_POP, RE_POP, FAKE_REPOP
          body = {
            ...commonProps,
            is_send_push_notification: checkedDefaultPop,
            data: {
              pop_title: data.pop_title,
              pop_body: data.pop_body,
              ack_btn_text: data.ack_btn_text,
              is_repop_send_push_notification: checkedRepop,
              push_title:
                data.pop_type === POP_TYPES.DEFAULT_POP
                  ? data.push_title
                  : data.repop_push_title,
              push_body:
                data.pop_type === POP_TYPES.DEFAULT_POP
                  ? data.push_body
                  : data.repop_push_body,
              repop_push_title:
                data.pop_type === POP_TYPES.DEFAULT_POP ? '' : data.push_title,
              repop_push_body:
                data.pop_type === POP_TYPES.DEFAULT_POP ? '' : data.push_body,
              profile_image_url: computedProfileImageUrl,
              pop_image_url:
                data.pop_image_url !== '' ? data.pop_image_url : null,

              ...(data.repop_main_btn &&
                (data.pop_type === POP_TYPES.RE_POP ||
                  data.pop_type === POP_TYPES.FAKE_REPOP) && {
                  repop_main_btn: data.repop_main_btn,
                }),
              ...(data.video && { video: data.video }),
              ...(data.audio && { audio: data.audio }),
              ...(data.repop_sec_btn &&
                (data.pop_type === POP_TYPES.RE_POP ||
                  data.pop_type === POP_TYPES.FAKE_REPOP) && {
                  repop_sec_btn: data.repop_sec_btn,
                }),
              ...(data.pop_type === POP_TYPES.RE_POP &&
                isPopPost && { is_pop_post: isPopPost }),
              ...(data.repop_contexts_ids &&
                data.pop_type === POP_TYPES.RE_POP && {
                  repop_users: data.contexts_ids,
                  repop_tags: data.tags_context,
                  repop_groups: [],
                }),
            },
            users:
              data.pop_type === POP_TYPES.RE_POP ||
              data.pop_type === POP_TYPES.FAKE_REPOP
                ? data.repop_contexts_ids
                : data.contexts_ids,
            ...(data.pop_type === POP_TYPES.DEFAULT_POP && {
              tags: data.tags_context,
            }),
          }
          break
      }
      await createPop(handleSubmissionResponse, body)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    reset()
    setCheckedIds([])
    setVideoLinkError(false)
    setAudioLinkError(false)
    setPopImageError(false)
  }, [selected_rid])

  useEffect(() => {
    if (isSubmitted === false) {
      field.onChange(checkedIds)
      tags_context.onChange(checkedTags)
      if (
        popType === POP_TYPES.RE_POP ||
        popType === POP_TYPES.FAKE_REPOP ||
        popType === POP_TYPES.AI_REPOP
      )
        repop_contexts_ids.onChange(checkedRepopIds)
    } else
      return () => {
        setIsSubmitted(false)
      }
  }, [checkedIds, checkedTags, popType, checkedRepopIds, isSubmitted])

  useEffect(() => {
    if (popType === POP_TYPES.AI_POP || popType === POP_TYPES.AI_REPOP) {
      setPopBodyHasMaxHeight(false)
    }
  }, [popType])

  return (
    <CreatePopWrapper>
      <CreatePop
        loading={loading}
        watch={watch}
        register={register}
        reset={reset}
        errors={errors}
        users={users}
        checkedIds={checkedIds}
        setCheckedIds={setCheckedIds}
        checkedRepopIds={checkedRepopIds}
        setCheckedRepopIds={setCheckedRepopIds}
        handleSubmit={handleSubmit(onSubmit)}
        resetField={resetField}
        setPopBodyHasMaxHeight={setPopBodyHasMaxHeight}
        isPopBodyHasMaxHeight={isPopBodyHasMaxHeight}
        isPopImageError={isPopImageError}
        isProfileImageError={isProfileImageError}
        setValue={setValue}
        setIsRepopView={setIsRepopView}
        isPopPost={isPopPost}
        setIsPopPost={setIsPopPost}
        isCustomAvatar={isCustomAvatar}
        setIsCustomAvatar={setIsCustomAvatar}
        checkedTags={checkedTags}
        setCheckedTags={setCheckedTags}
        retailTags={retailTags}
        isTimeLess={isTimeLess}
        setIsTimeLess={setIsTimeLess}
        isSubmitted={isSubmitted}
        selected_rid={selected_rid}
        isVideoLinkError={isVideoLinkError}
        isAudioLinkError={isAudioLinkError}
        checkedDefaultPop={checkedDefaultPop}
        setCheckedDefaultPop={setCheckedDefaultPop}
        checkedRepop={checkedRepop}
        setCheckedRepop={setCheckedRepop}
      />
      <Divider orientation="vertical" flexItem style={{ height: 'inherit' }} />
      <PopView
        watch={watch}
        setPopBodyHasMaxHeight={setPopBodyHasMaxHeight}
        setPopImageError={setPopImageError}
        setProfileImageError={setProfileImageError}
        setIsRepopView={setIsRepopView}
        isRepopView={isRepopView}
        isCustomAvatar={isCustomAvatar}
        setVideoLinkError={setVideoLinkError}
        setAudioLinkError={setAudioLinkError}
      />
    </CreatePopWrapper>
  )
}

const mapStateToProps = ({
  users,
  selected_rid,
  logout,
  handleAlert,
  groups,
  retailTags,
}) => ({
  users,
  groups,
  selected_rid,
  logout,
  handleAlert,
  retailTags,
})

export default connect(mapStateToProps, actions)(PopCreation)
